import React, { useEffect, useMemo, useRef, useState } from "react";
import { Search, ChevronRight, Info } from "react-feather";
import { debouncer } from "../utils/helper";
import { getSearchResults } from "../services/search.services";
import { toastify } from "./core/Toast";
import CustomLoader from "./core/CustomLoader";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const navigate = useNavigate();
  const searchInput = useRef();
  const searchDivRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchResults, setSearchResults] = useState({
    products: [],
    categories: [],
    isInitialised: false,
  });
  const [searchWord, setSearchWord] = useState("");

  // Hide search bar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchDivRef.current &&
        !searchDivRef.current.contains(event.target) &&
        !searchInput.current.contains(event.target)
      ) {
        setIsSearchVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Debounced search function
  const debouncedSearch = useMemo(
    () =>
      debouncer(async (searchTerm) => {
        setIsLoading(true);
        try {
          if (searchTerm && searchTerm !== "") {
            const getSearch = await getSearchResults(searchTerm);
            if (
              getSearch.status === 200 &&
              (getSearch?.data?.products || getSearch?.data?.categories)
            ) {
              setSearchResults({
                products: getSearch?.data?.products || [],
                categories: getSearch?.data?.categories || [],
                isInitialised: true,
              });
              setIsSearchVisible(true);
            }
          } else {
            setSearchResults({
              products: [],
              categories: [],
              isInitialised: false,
            });
            // setIsSearchVisible(false); // Hide dropdown if search term is empty
          }
        } catch (error) {
          if (error?.message === "No matches found.") {
            setSearchResults({
              products: [],
              categories: [],
              isInitialised: true,
            });
            setIsSearchVisible(true);
          } else {
            toastify(
              `Error while searching :${
                error?.message ? error?.message : error
              }`,
              "error"
            );
          }
        } finally {
          setIsLoading(false);
        }
      }, 500),
    []
  );

  const handleCategoryClick = (cat) => {
    if (cat?.id) {
      navigate(`/category/${cat.id}`);
      setSearchResults({
        products: [],
        categories: [],
        isInitialised: false,
      });
      setSearchWord("");
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchWord(value);
    debouncedSearch(value);
  };

  const handleFocus = () => {
    setIsSearchVisible(true);
  };

  return (
    <div
      className={`group relative w-2/3 rounded-[8px] z-50 ${
        isSearchVisible ? " shadow-[0_1px_30px_rgb(0,0,0,0.12)] " : ""
      }`}
      ref={searchDivRef}
    >
      <div
        className={`flex pl-2 py-3 justify-center items-center gap-3 bg-white z-50 border ${
          isSearchVisible
            ? "  rounded-t-[8px] border-white"
            : "  border-primary-border rounded-[8px] "
        }`}
      >
        <Search className="ml-1" size={22} />
        <input
          placeholder="Search Spices, Products ..."
          className="px-2 bg-transparent flex-1 text-black"
          onChange={handleInputChange}
          onFocus={handleFocus}
          ref={searchInput}
          value={searchWord}
        />
      </div>
      <div
        className={`top-10  py-2 px-2 rounded-b-[8px] w-full max-h-[500px] min-h-[100px] shadow-[0_30px_30px_rgb(0,0,0,0.12)] absolute z-10 bg-white overflow-auto transition-all duration-200 ease-in-out  ${
          isSearchVisible ? " visible opacity-100 " : " opacity-0 invisible"
        }`}
      >
        {isLoading && <CustomLoader size={44} color={"#000"} />}

        {!isLoading && searchResults.products.length > 0 && (
          <>
            <div className="mt-4 mb-1 flex justify-between items-center">
              <div className="flex items-center gap-4">
                <div className="text-[20px]">Products</div>
                <div
                  className={`inline-flex items-center justify-center w-7 h-7 rounded-full bg-primary-accent text-white font-bold`}
                  style={{ fontSize: `${2.5 / 3}rem` }}
                >
                  {searchResults.products.length}
                </div>
              </div>
            </div>
            {searchResults.products.map((el) => (
              <div className="flex rounded-[8px] cursor-pointer gap-4 items-center h-16 w-full px-2 hover:bg-primary-gray hover:text-black text-gray-600 transition-colors duration-200 ease-in-out">
                <div className="w-10 h-10 rounded-full overflow-hidden flex justify-center items-center">
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/${el?.images[0]?.image_url}`}
                    alt={el.name}
                    className="object-cover w-full h-full"
                    loading="lazy"
                  />
                </div>
                <div className="flex-1 h-full justify-center flex flex-col">
                  <h3 className="">{el.name}</h3>
                  <p className="text-gray-400 text-[14px] line-clamp-1">
                    {el.description}
                  </p>
                </div>
                <div className="">
                  <ChevronRight />
                </div>
              </div>
            ))}
          </>
        )}

        {!isLoading && searchResults.categories.length > 0 && (
          <>
            <div className="mt-4 mb-1 flex justify-between items-center">
              <div className="flex items-center gap-4">
                <div className="text-[20px]">Categories</div>
                <div
                  className={`inline-flex items-center justify-center w-7 h-7 rounded-full bg-primary-accent text-white font-bold`}
                  style={{ fontSize: `${2.5 / 3}rem` }}
                >
                  {searchResults.categories.length}
                </div>
              </div>
            </div>
            {searchResults.categories.map((el) => (
              <div
                className="flex rounded-[8px] cursor-pointer gap-4 items-center h-16 w-full px-2 hover:bg-primary-gray hover:text-black text-gray-600 transition-colors duration-200 ease-in-out"
                onClick={() => {
                  handleCategoryClick(el);
                }}
              >
                <div className="w-10 h-10 rounded-full overflow-hidden flex justify-center items-center">
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/${el.image}`}
                    alt={el.name}
                    className="object-cover w-full h-full"
                    loading="lazy"
                  />
                </div>
                <div className="flex-1 h-full justify-center flex flex-col">
                  <h3 className="">{el.name}</h3>
                  <p className="text-gray-400 text-[14px] line-clamp-1">
                    {el.description}
                  </p>
                </div>
                <div className="">
                  <ChevronRight />
                </div>
              </div>
            ))}
          </>
        )}

        {searchResults.isInitialised &&
          !isLoading &&
          searchResults.products.length === 0 &&
          searchResults.categories.length === 0 && (
            <div className="relative py-4 w-full flex justify-center items-center flex-col gap-1">
              <img
                src="/images/search_abstract.svg"
                alt="svg"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
              <Info color="orange" size={32} />
              <p className="font-bold">No matches found</p>
              <p className="text-gray-500 text-center leading-5">
                "{searchWord}" did not match any products or categories. <br />
                Please try again.
              </p>
            </div>
          )}

        {!searchResults.isInitialised && !isLoading && (
          <div className="relative w-full flex justify-center items-center flex-col gap-1 min-h-[84px]">
            <img
              src="/images/search_abstract.svg"
              alt="svg"
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
            <p className="font-bold">
              Explore an extensive range of spices, herbs, and more.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
