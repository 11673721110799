import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
// import { persistStore } from "redux-persist";
import { logger } from "redux-logger";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      logger,
    ];
  },
});

// export const persistor = persistStore(store);
// const exportStore = { store, persistor: persistor }
const exportStore = { store };
export default exportStore;
