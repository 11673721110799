import React, { useState } from "react";
import Modal from "../core/Modal";
import { Mail, Lock, User, ChevronRight, Phone, Calendar } from "react-feather";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import FormInput from "../core/FormInput";
import { register } from "../../services/auth.services.js";
import { toastify } from "../core/Toast.jsx";
import CustomButton from "../core/CustomButton.jsx";
import {
  fetchUserDetailsAction,
  loginUserAction,
} from "../../redux/user/user.actions.js";

const signUpInitialValues = {
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  password: "",
  phone_number: "",
  birthdate: "",
};

const loginInitialValues = {
  login: "",
  password: "",
};

const AuthModal = ({ setIsAuthModalVisible }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const SignUpForm = () => {
    const signUpValidationSchema = yup.object().shape({
      first_name: yup.string().trim().required("Firstname is required"),
      last_name: yup.string().trim().required("Lastname is required"),
      username: yup.string().trim().required("Username is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      password: yup
        .string()
        .min(7, "Password must be at least 7 characters long")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
      phone_number: yup
        .string()
        .matches(/^\d{10}$/, "Invalid mobile number")
        .length(10, "Mobile number must be exactly 10 digits long")
        .required("Mobile number is required"),
      birthdate: yup.date().optional(),
    });

    const onSignUpSubmit = async (values) => {
      try {
        setIsLoading(true);
        const registerUser = await register({
          ...values,
          phone_number: "+91" + values.phone_number,
        });

        if (registerUser && registerUser.status === 201 && registerUser.data) {
          toastify("Signed up successfully, please login to your account");
          setIsLogin(true);
        }
      } catch (error) {
        toastify(error, "error");
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Formik
        initialValues={signUpInitialValues}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={signUpValidationSchema}
        onSubmit={onSignUpSubmit}
      >
        <Form>
          <div className="mt-10">
            <div className="flex gap-2">
              <Field name="first_name">
                {({ field, meta }) => (
                  <FormInput
                    placeholder="First name"
                    error={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
              <Field name="last_name">
                {({ field, meta }) => (
                  <FormInput
                    placeholder="Last name"
                    error={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <Field name="username">
              {({ field, meta }) => (
                <FormInput
                  placeholder="Username"
                  icon={<User className="shrink-0 text-primary-accent" />}
                  error={meta.touched && meta.error}
                  wrapperClass={`mt-3`}
                  {...field}
                />
              )}
            </Field>
            <Field name="email">
              {({ field, meta }) => (
                <FormInput
                  placeholder="E-mail"
                  icon={<Mail className="shrink-0 text-primary-accent" />}
                  wrapperClass={`mt-3`}
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <Field name="birthdate">
              {({ field, meta }) => (
                <FormInput
                  type="date"
                  placeholder="Date of birth"
                  icon={<Calendar className="shrink-0 text-primary-accent" />}
                  wrapperClass={`mt-3`}
                  error={meta.touched && meta.error}
                  max={new Date().toISOString().substring(0, 10)}
                  {...field}
                />
              )}
            </Field>
            <Field name="phone_number">
              {({ field, meta }) => (
                <FormInput
                  placeholder="Phone"
                  icon={<Phone className="shrink-0 text-primary-accent" />}
                  wrapperClass={`mt-3`}
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <Field name="password">
              {({ field, meta }) => (
                <FormInput
                  type="password"
                  placeholder="Password"
                  icon={<Lock className="shrink-0 text-primary-accent" />}
                  wrapperClass={`mt-3`}
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <CustomButton isSubmit customClass="w-full" isLoading={isLoading}>
              Sign up
            </CustomButton>
          </div>
        </Form>
      </Formik>
    );
  };

  const LoginForm = () => {
    const loginValidationSchema = yup.object().shape({
      login: yup.string().trim().required("Username/Phone is required"),
      password: yup
        .string()
        .min(7, "Password must be at least 7 characters long")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
    });

    const onLoginSubmit = async (values) => {
      try {
        setIsLoading(true);
        const userLogin = await loginUserAction(values);
        if (userLogin) {
          await fetchUserDetailsAction();
          setIsAuthModalVisible(false);
        }
      } catch (error) {
        toastify(
          error.error || error.message || "Error while loggin in",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    };
    return (
      <Formik
        initialValues={loginInitialValues}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={loginValidationSchema}
        onSubmit={onLoginSubmit}
      >
        <Form>
          <div className="mt-10">
            <Field name="login">
              {({ field, meta }) => (
                <FormInput
                  placeholder="Username / Phone"
                  icon={<User className="shrink-0 text-primary-accent" />}
                  error={meta.touched && meta.error}
                  wrapperClass={`mt-3`}
                  {...field}
                />
              )}
            </Field>
            <Field name="password">
              {({ field, meta }) => (
                <FormInput
                  type="password"
                  placeholder="Password"
                  icon={<Lock className="shrink-0 text-primary-accent" />}
                  wrapperClass={`mt-3`}
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
            <p className="text-primary-accent text-right mt-3">
              Forgot Password
            </p>

            <CustomButton
              isSubmit
              customClass="w-full mt-5"
              isLoading={isLoading}
            >
              Log in
            </CustomButton>
          </div>
        </Form>
      </Formik>
    );
  };

  return (
    <Modal
      onCLose={() => {
        setIsAuthModalVisible(false);
      }}
      closeOnClickOutside
    >
      <div className="h-full w-full flex relative justify-between">
        <div className="absolute h-full w-full top-0 left-0 ">
          {/* <Spline scene="https://prod.spline.design/x4rYOCpUnXASLnJE/scene.splinecode" /> */}
          <img
            src="/images/auth/abstractArt.png"
            className="w-full h-full object-cover rounded-[8px]"
            alt="text"
          />
        </div>

        <div
          className={`absolute h-full z-50 flex flex-col rounded-[8px] justify-center items-center  w-1/2 transition-all ease-in-out duration-500 ${
            !isLogin ? " translate-x-0 " : " translate-x-full "
          }`}
        >
          <div className="w-[80%]  px-4 pt-10 pb-5 rounded-[8px] z-10 bg-[#0000008e] ">
            <div className=" text-white font-bold text-[48px] leading-[50px] capitalize ">
              Shop exquisite authentic masalas
            </div>
            {isLogin && (
              <div className="rounded-[8px] mt-6">
                <p className=" text-white">Dont have an account ?</p>
                <p
                  className="text-primary-accent cursor-pointer flex items-center gap-2 bg-primary-accent text-white rounded-[8px] px-2 py-2 w-fit mt-2"
                  onClick={() => {
                    setIsLogin(false);
                  }}
                >
                  Sign up <ChevronRight size={18} />
                </p>
              </div>
            )}

            {!isLogin && (
              <div className="rounded-[8px]  mt-6">
                <p className=" text-white">Already have an account ?</p>
                <p
                  className="text-primary-accent cursor-pointer flex items-center gap-2 bg-primary-accent text-white rounded-[8px] px-2 py-2 w-fit mt-2"
                  onClick={() => {
                    setIsLogin(true);
                  }}
                >
                  Log in <ChevronRight size={18} />
                </p>
              </div>
            )}
          </div>
        </div>

        <div
          className={`  h-full flex flex-col justify-center items-center bg-white z-10 transition-all ease-in-out duration-500 ${
            isLogin ? " w-1/2 " : " w-0 "
          }`}
        >
          <div
            className={`text-left w-[70%] transition-all ease-in-out duration-1000 ${
              isLogin ? " opacity-100 " : "  opacity-0 "
            }`}
          >
            {isLogin && (
              <>
                <h1 className="text-left text-[36px]">
                  Log in to your account
                </h1>
                <LoginForm />
              </>
            )}
          </div>
        </div>
        <div
          className={`  h-full flex flex-col justify-center items-center  bg-white z-10 transition-all ease-in-out duration-500 ${
            !isLogin ? " w-1/2 " : " w-0 "
          }`}
        >
          <div
            className={`text-left w-[70%] transition-all ease-in-out duration-1000 ${
              !isLogin ? " opacity-100 " : " opacity-0 "
            }`}
          >
            {!isLogin && (
              <>
                <h1 className="text-left text-[36px]">Sign up</h1>
                <SignUpForm />
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AuthModal;
