import { getCategoriesApi } from "../../services/category.services";
import { getBestSellersProductsApi } from "../../services/products.services";
import { store } from "../store";
import { handleCategories, handleBestSellers } from "./product.slice";

export const getCategoriesAction = async () => {
  try {
    const categories = await getCategoriesApi();
    if (categories && categories.status === 200 && categories?.data?.length) {
      store.dispatch(handleCategories(categories.data));
    }
  } catch (error) {
    throw error;
  }
};

export const getBestSellersProductsAction = async () => {
  try {
    const bestSellers = await getBestSellersProductsApi();
    if (
      bestSellers &&
      bestSellers.status === 200 &&
      bestSellers?.data?.length
    ) {
      store.dispatch(handleBestSellers(bestSellers.data));
    }
  } catch (error) {
    throw error;
  }
};
