import React, { useState } from "react";
import {
  Phone,
  Mail,
  Info,
  Briefcase,
  UserPlus,
  Coffee,
  Truck,
  MapPin,
  ChevronRight,
} from "react-feather";
import { Link } from "react-router-dom";
import GoogleMaps from "../components/core/GoogleMaps";

const addresses = [
  {
    id: 1,
    name: "1, Dr Vikram Sarabhai Marg, University Area, Ahmedabad, Gujarat 380015",
    lat: 23.028629124749045,
    lng: 72.543677113558,
  },
  {
    id: 2,
    name: "Shop No.11, ground Floor, Prernatirth Derasar Rd, Vraj Vihar 3, Jodhpur Village, Ahmedabad, Gujarat 380015",
    lat: 23.03187926731452,
    lng: 72.55495527211175,
  },
];

const Footer = () => {
  const [selectedLocation, setSelectedLocation] = useState(addresses[0]);
  const contactus = [
    {
      image: <Mail size={18} />,
      title: "gujjumasala@gmail.com",
      href: "mailto:gujjumasala@gmail.com",
    },
    {
      image: <Phone size={18} />,
      title: "+91 9998887771",
      href: "tel:+919998887771",
    },
  ];

  const Information = [
    {
      title: "About",
      href: "",
      image: <Info size={18} />,
    },
    {
      title: "Careers",
      href: "",
      image: <Briefcase size={18} />,
    },
    {
      title: "Wholesales",
      href: "",
      image: <Truck size={18} />,
    },
    {
      title: "Collaborations",
      href: "",
      image: <UserPlus size={18} />,
    },
    {
      title: "Chef's Corner",
      href: "",
      image: <Coffee size={18} />,
    },
  ];

  const handleClick = (location) => {
    setSelectedLocation({ lat: location.lat, lng: location.lng });
  };

  return (
    <div className="bg-[#1d291c] w-full text-white ">
      <div className="w-full flex py-8">
        <div className="w-[47%]  pl-[50px] pr-[40px] flex gap-8 py-4 justify-between">
          <div className=" border border-primary-border aspect-square w-1/2 shrink-0">
            Image
          </div>
          <div className="shrink-0 flex flex-col gap-10 flex-1">
            <div>
              <div className="border-b border-primary-border w-full pb-[2.5px]">
                Information
              </div>
              <div className="mt-3">
                {Information.map((el, i) => (
                  <Link
                    key={`${el.title} -- ${i}`}
                    to={el.href}
                    className="w-fit"
                  >
                    <div className="flex py-[8px] items-center">
                      <span className="shrink-0">{el.image}</span>
                      <span className="ml-4 text-[14px]">{el.title}</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <div className="border-b border-primary-border w-full pb-[2.5px]">
                Contact Us
              </div>
              <div className="mt-3">
                {contactus.map((el, i) => (
                  <Link
                    key={`${el.title} -- ${i}`}
                    to={el.href}
                    className="w-fit"
                  >
                    <div className="flex py-[8px] items-center">
                      <span className="shrink-0">{el.image}</span>
                      <span className="ml-4 text-[14px]">{el.title}</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[53%]   flex pr-[50px] justify-between py-4 gap-8">
          <div className="w-1/2">
            <div className="border-b border-primary-border w-full pb-[2.5px] mb-4">
              Locate Us
            </div>
            <div className="flex flex-col gap-4">
              {addresses.map((addr) => (
                <div
                  className="flex items-start  px-3 py-1 rounded-[10px] gap-6 cursor-pointer"
                  onClick={() => handleClick(addr)}
                  key={addr.id}
                >
                  <MapPin size={18} className="shrink-0 mt-[5px]" />
                  <div className="text-[14px]">{addr.name}</div>
                  <ChevronRight size={18} className="shrink-0 mt-[5px]" />
                </div>
              ))}
            </div>
          </div>
          <div className="w-1/2">
            <GoogleMaps
              selectedLocation={{
                lat: selectedLocation.lat,
                lng: selectedLocation.lng,
              }}
            />
          </div>
        </div>
      </div>

      <div className="w-full px-[50px] border-t border-primary-border py-4 flex flex-row justify-between items-center bg-primary-light-accent text-black">
        <div className="flex justify-between items-center gap-8 w-full">
          <div className="flex justify-center items-center gap-4">
            <div className="w-10 aspect-square border border-primary-border"></div>
            <div>
              <p className="font-bold">gujju.pepperandspices</p>
              <p className="text-[14px]">© All rights reserved</p>
            </div>
          </div>
          <div className="flex justify-center items-center gap-4">
            <div className="w-10 aspect-square border border-primary-border"></div>
            <div>
              <p className="font-bold">Developed and Managed by</p>
              <p className="text-[14px]">CodeKarmaTech</p>
            </div>
          </div>
          <div className="flex justify-center items-center gap-4">
            <div className="w-10 aspect-square border border-primary-border"></div>
            <div>
              <p className="font-bold">curate . design . strategy</p>
              <p className="text-[14px] tracking-[0.4px]">
                pod . perceptionsofdesign
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
