import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  bestSellers: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    handleCategories: (state, action) => {
      state.categories = action.payload;
    },
    handleBestSellers: (state, action) => {
      state.bestSellers = action.payload;
    },
  },
});

export const { handleCategories, handleBestSellers } = productSlice.actions;

export default productSlice.reducer;
