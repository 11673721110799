import React from "react";

const CustomLoader = ({ size = 24, color, width = 4, backDrop = false }) => {
  return (
    <div
      className={`absolute top-0 left-0 w-full h-full flex justify-center items-center  z-10 ${
        backDrop ? " bg-[#0000005b] " : "  "
      }`}
    >
      <div
        style={{
          height: size + "px",
          width: size + "px",
          borderTopColor: color,
          borderWidth: width + "px",
        }}
        className={` border-white animate-spin rounded-full ${
          !color ? " border-t-primary-light-accent " : ""
        }`}
      ></div>
    </div>
  );
};

export default CustomLoader;
