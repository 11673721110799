import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "400px",
};

function GoogleMaps({ selectedLocation }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (map && selectedLocation) {
      map.panTo(selectedLocation);
    }
  }, [map, selectedLocation]);

  const handleGetDirections = () => {
    const origin = "Your Current Location";
    const destination = `${selectedLocation.lat},${selectedLocation.lng}`;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
    window.open(googleMapsUrl, "_blank");
  };

  return isLoaded ? (
    <div className="relative">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={selectedLocation}
        zoom={18}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {selectedLocation && <Marker position={selectedLocation} />}{" "}
      </GoogleMap>
      {selectedLocation && (
        <button onClick={handleGetDirections} className="absolute top-0 left-0">
          Get Directions
        </button>
      )}
    </div>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMaps);
