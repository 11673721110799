import { Country, State, City } from "country-state-city";

export const products = [
  {
    name: "Cinnamon",
    category: "Whole Spices",
    price: "12.00",
    img: "1.webp",
  },
  {
    name: "Turmeric",
    category: "Basic Spices",
    price: "8.50",
    img: "2.webp",
  },
  {
    name: "Cumin Seeds",
    category: "Whole Spices",
    price: "6.75",
    img: "3.webp",
  },
  {
    name: "Coriander Powder",
    category: "Ground Spices",
    price: "5.25",
    img: "4.webp",
  },
  {
    name: "Cardamom Pods",
    category: "Whole Spices",
    price: "15.50",
    img: "5.webp",
  },
  {
    name: "Fenugreek Seeds",
    category: "Whole Spices",
    price: "4.90",
    img: "6.webp",
  },
  {
    name: "Mustard Seeds",
    category: "Whole Spices",
    price: "7.20",
    img: "7.webp",
  },
  {
    name: "Garam Masala",
    category: "Blended Spices",
    price: "9.99",
    img: "8.webp",
  },
  {
    name: "Chili Powder",
    category: "Ground Spices",
    price: "3.99",
    img: "9.webp",
  },
  {
    name: "Cloves",
    category: "Whole Spices",
    price: "11.25",
    img: "10.webp",
  },
  {
    name: "Asafoetida",
    category: "Specialty Spices",
    price: "7.50",
    img: "11.webp",
  },
  {
    name: "Bay Leaves",
    category: "Herbs and Leaves",
    price: "2.75",
    img: "12.webp",
  },
];

export const getShuffledArray = (sliceVal) => {
  const shuffledArray = [...products];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray.slice(0, sliceVal);
};

export const getCountries = Country.getAllCountries()
  .filter((el) => el.name.toLowerCase() === "india")
  .map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));

export const getStates = State.getStatesOfCountry("IN").map((state) => ({
  label: state.name,
  value: state.name,
  ...state,
}));

export const getCities = (countryCode, stateIsoCode) =>
  City.getCitiesOfState(countryCode, stateIsoCode).map((state) => ({
    label: state.name,
    value: state.name,
    ...state,
  }));

export const getAccessToken = () => {
  const accessToken = localStorage.getItem("token");
  if (accessToken && accessToken !== "null") {
    return accessToken;
  }
  return null;
};

export function debouncer(func, delay) {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(context, args), delay);
  };
}
