import { lazy } from "react";

export const publicRoutes = [
  {
    path: "/",
    name: "Home",
    component: lazy(() => import("../pages/HomePage.js")),
    meta: {
      breadcrumb: [{ title: "Home" }],
      slug: "HOME",
      authRequired: false,
      isPublicLayout: true,
    },
  },
  {
    path: "/about-us",
    name: "About Us",
    component: lazy(() => import("../pages/AboutPage.js")),
    meta: {
      breadcrumb: [{ title: "About Us" }],
      slug: "ABOUT_US",
      authRequired: false,
      isPublicLayout: true,
    },
  },
  {
    path: "/category/:id",
    name: "CategoryProducts",
    component: lazy(() => import("../pages/CategoryDetailsPage.js")),
    meta: {
      breadcrumb: [{ title: "Category" }],
      slug: "CATEGORY_PRODUCTS",
      authRequired: false,
      isPublicLayout: true,
    },
  },
  {
    path: "/product/:id",
    name: "ProductDetails",
    component: lazy(() => import("../pages/ProductDetailsPage.js")),
    meta: {
      breadcrumb: [{ title: "Product Details" }],
      slug: "PRODUCT_DETAILS",
      authRequired: false,
      isPublicLayout: true,
    },
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: lazy(() => import("../pages/NotFoundPage.js")),
    meta: {
      breadcrumb: [{ title: "Not Found" }],
      slug: "NOT_FOUND",
      authRequired: false,
      isPublicLayout: true,
    },
  },
];

const privateRoutes = [
  {
    path: "/profile",
    name: "Profile",
    component: lazy(() => import("../pages/ProfilePage.js")),
    meta: {
      breadcrumb: [{ title: "Home" }, { title: "Profile" }],
      slug: "PROFILE",
      authRequired: true,
      isPublicLayout: true,
    },
  },
];

// const privateRoutes = [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     component: lazy(() => import("../pages/admin/Dashboard.js")),
//     meta: {
//       breadcrumb: [{ title: "Dashboard" }],
//       slug: "/dashboard",
//       isProtected: true,
//       isPublicLayout: false,
//     },
//   },
//   {
//     path: "/event",
//     name: "Event Dashboard",
//     component: lazy(() => import("../pages/admin/EventDashboard.js")),
//     meta: {
//       breadcrumb: [{ title: "Event-Dashboard" }],
//       slug: "/eventdashboard",
//       isProtected: true,
//       isPublicLayout: false,
//     },
//   },
// ];

export const routes = [...publicRoutes, ...privateRoutes];
