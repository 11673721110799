import React, { useEffect, useRef, useState } from "react";
import { Search, User, ShoppingCart, Box, LogOut } from "react-feather";
import CartDrawer from "../components/drawers/CartDrawer";
import AuthModal from "../components/modals/AuthModal";
import Dropdown from "../components/core/Dropdown";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { logoutUserAction } from "../redux/user/user.actions";
import SearchBar from "../components/SearchBar";

const Header = () => {
  const [visible, setVisible] = useState(true);
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const navigate = useNavigate();
  const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
  const categories = useSelector((state) => state.product.categories);
  const categoryOverlayRef = useRef();

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(currentScrollPos > 200);
  };

  useEffect(() => {
    window !== undefined && window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const userDropDownOptions = [
    {
      label: "Profile",
      name: "profile",
      url: "/profile",
      icon: <User size={16} />,
    },
    {
      label: "Orders",
      name: "orders",
      url: "/profile?tab=all_orders",
      icon: <Box size={16} />,
    },
    {
      label: "Log out",
      name: "logout",
      action: "logout",
      icon: <LogOut size={16} />,
    },
  ];

  const onUserDropDownClick = (option) => {
    if (option?.url) {
      navigate(option.url);
    } else if (option?.action) {
      if (option?.action === "logout") {
        logoutUserAction();
      }
    }
  };

  const handleMouseEnter = (e) => {
    const rect = e.target.getBoundingClientRect();
    categoryOverlayRef.current.style.width = rect.width + "px";
    categoryOverlayRef.current.style.height = rect.height + "px";
    categoryOverlayRef.current.style.transform = `translateX(${
      rect.left - 16
    }px)`;
  };

  const handleCategoryClick = (cat) => {
    if (cat?.id) {
      navigate(`/category/${cat.id}`);
    }
  };

  return (
    <>
      <div
        //
        className={`w-full  bg-white px-4 fixed  left-0 transition-all duration-300 ease-in-out z-40 top-0 ${
          visible
            ? " shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] "
            : "  "
        }`}
      >
        <div className={`flex justify-between h-[70px] items-end`}>
          <div>Logo</div>
          <div className="flex w-3/4 gap-4">
            <SearchBar />

            <div className="flex justify-between items-center gap-3">
              {isUserLoggedIn && (
                <Dropdown
                  trigger={
                    <div className="bg-primary-accent gap-2 h-[40px] px-3 flex justify-center items-center cursor-pointer rounded-[8px] text-white  transition-all duration-500 ease-in-out border border-primary-border">
                      <User size={18} /> Account
                    </div>
                  }
                  offsetX={0}
                  offsetY={40}
                  minWidth={250}
                >
                  {isUserLoggedIn &&
                    userDropDownOptions.map((el, i) => (
                      <div
                        className={`cursor-pointer flex items-center gap-3    rounded-[8px] px-2 py-1 ${
                          el.name === "logout"
                            ? " bg-primary-red text-white "
                            : " hover:bg-primary-gray hover:text-black text-primary-neutral-text  "
                        } `}
                        key={`${i}_${el.name}`}
                        onClick={() => {
                          onUserDropDownClick(el);
                        }}
                      >
                        {el.icon} {el.label}
                      </div>
                    ))}
                </Dropdown>
              )}

              {!isUserLoggedIn && (
                <div
                  className="bg-primary-accent gap-2 h-[40px] px-3 flex justify-center items-center cursor-pointer rounded-[8px] text-white hover:text-black  hover:bg-white transition-all duration-500 ease-in-out border border-primary-border"
                  onClick={() => {
                    setIsAuthModalVisible(true);
                  }}
                >
                  <User size={18} /> Login
                </div>
              )}

              <div
                className="bg-primary-accent  h-[40px] px-3 flex justify-center items-center cursor-pointer rounded-[8px] text-white hover:text-black hover:bg-white transition-all duration-500 ease-in-out border border-primary-border"
                onClick={() => {
                  setIsCartDrawerOpen(true);
                }}
              >
                <ShoppingCart />
              </div>
            </div>
          </div>
        </div>
        <div className="flex relative justify-between  items-center mt-4 text-[18px]">
          <div className="flex gap-2 capitalize relative peer ">
            {categories?.length > 0 &&
              categories.map((el) => (
                <div
                  onMouseEnter={handleMouseEnter}
                  onClick={() => {
                    handleCategoryClick(el);
                  }}
                  className="w-fit z-10 px-2 py-1 rounded-[8px] cursor-pointer pb-3"
                  key={`categories-${el.id}`}
                >
                  {el.name}
                </div>
              ))}
          </div>
          <div className="flex gap-2 capitalize peer">
            <p
              className="w-fit z-10 px-2 py-1 rounded-[8px] cursor-pointer"
              onMouseEnter={handleMouseEnter}
            >
              About Us
            </p>
            <p
              className="w-fit z-10 px-2 py-1 rounded-[8px] cursor-pointer"
              onMouseEnter={handleMouseEnter}
            >
              Contact Us
            </p>
          </div>
          <span
            className="absolute  top-0 left-0 bg-primary-light-accent transition-all duration-200 ease-in-out peer-hover:visible invisible opacity-0 peer-hover:opacity-100"
            ref={categoryOverlayRef}
          ></span>
        </div>
      </div>
      <CartDrawer
        isCartDrawerOpen={isCartDrawerOpen}
        setIsCartDrawerOpen={setIsCartDrawerOpen}
      />
      {isAuthModalVisible && (
        <AuthModal setIsAuthModalVisible={setIsAuthModalVisible} />
      )}
    </>
  );
};

export default Header;
