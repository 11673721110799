import React from "react";
// import { getShuffledArray } from "../utils/helper";
import Drawer from "../core/Drawer";
import { getShuffledArray } from "../../utils/helper";

const CartDrawer = React.memo(({ isCartDrawerOpen, setIsCartDrawerOpen }) => {
  return (
    <Drawer
      isDrawerOpen={isCartDrawerOpen}
      onDrawerClose={() => {
        setIsCartDrawerOpen(false);
      }}
      header={"Cart"}
    >
      <div className="flex flex-col justify-between items-center h-full w-full">
        <div className="mt-8 w-full">
          <div className="flow-root">
            <ul className="-my-6 divide-y divide-gray-200 dark:divide-medium-gray">
              {getShuffledArray(2).map((item) => (
                <li className="flex py-6" key={item.name}>
                  <div className="h-24 w-24 flex shrink-0 overflow-hidden rounded-md border border-gray-200 dark:border-medium-gray justify-center items-center">
                    <img
                      src={`/images/products/${item.img}`}
                      alt="Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt."
                      className="w-[60%] object-fit object-center"
                    />
                  </div>

                  <div className="ml-4 flex flex-1 flex-col justify-between">
                    <div>
                      <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                        <h3>{item.name}</h3>
                        <p className="ml-4">Rs. {item.price}</p>
                      </div>
                    </div>
                    <div className="flex flex-1 items-center justify-between text-sm ">
                      <p className="text-gray-500 dark:text-gray-300">Qty 1</p>

                      <div className="flex">
                        <button
                          type="button"
                          className="font-medium text-white bg-primary-accent p-3 py-1 rounded-md"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-200 py-6 w-full ">
          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
            <p>Subtotal</p>
            <p>Rs. 262.00</p>
          </div>
          <p className="mt-0.5 text-sm text-gray-500 dark:text-gray-300">
            Shipping and taxes calculated at checkout.
          </p>
          <div className="mt-6">
            <a
              href="www.google.com"
              className="flex items-center justify-center rounded-md border border-transparent bg-primary-accent px-6 py-3 text-base font-medium text-white shadow-sm"
            >
              Checkout
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  );
});

export default CartDrawer;
