import axios from "axios";
import { logoutUserAction } from "../redux/user/user.actions";
import { getAccessToken } from "../utils/helper";

export const baseURL = process.env.REACT_APP_BACKEND_URL;

axios.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      logoutUserAction();
    }
    return Promise.reject(error);
  }
);

export const axiosPost = (url, data = {}, params = {}, shouldLoad = false) => {
  return axios
    .post(baseURL + url, data, { params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const axiosGet = (url, params = {}, shouldLoad = false) => {
  return axios
    .get(baseURL + url, { params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const axiosPut = (url, data = {}, params = {}, shouldLoad = false) => {
  return axios
    .put(baseURL + url, data, { params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const axiosDelete = (url, params = {}, shouldLoad = false) => {
  return axios
    .delete(baseURL + url, { params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error.response.data;
    });
};
export default axios;
