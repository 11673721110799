import React from "react";
import { X } from "react-feather";

const Drawer = ({
  isDrawerOpen = false,
  onDrawerClose = () => {},
  header,
  width = " w-1/4 ",
  isCloseButtonVisible = true,
  children,
}) => {
  return (
    <div>
      <div
        className={`fixed bg-[#3939399d] h-full w-full z-[50] cursor-pointer left-0 top-0  ${
          !isDrawerOpen && "hidden "
        }`}
        onClick={() => {
          onDrawerClose();
        }}
      ></div>

      <div
        className={`fixed bg-white dark:bg-dark-gray z-50 h-full right-0 top-0 ${width}  transform transition ease-in-out duration-500 ${
          isDrawerOpen ? " translate-x-0 " : " translate-x-full "
        }`}
      >
        <div className="flex h-full flex-col  shadow-xl bg-white dark:bg-dark-gray dark:bg-dark-accent-color dark:text-white">
          {header && (
            <div className="flex items-start justify-between  py-6 px-4 sm:px-6 shrink-0">
              <h2
                className="font-[Montemedium] text-lg uppercase dark:text-white"
                id="slide-over-title"
              >
                {header}
              </h2>
              <div className="ml-3 flex h-7 items-center">
                {isCloseButtonVisible && (
                  <button
                    className="-m-2 p-2 bg-primary-accent text-white rounded-full transform transition-all duration-300 hover:scale-110 "
                    onClick={() => {
                      onDrawerClose();
                    }}
                  >
                    <X />
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="flex-1 w-full px-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
