import React, { useEffect } from "react";
import { X } from "react-feather";

const Modal = ({
  height = 90,
  width = 90,
  children,
  onCLose = () => {},
  closeOnClickOutside = false,
  closeIconVisible = false,
}) => {
  // useEffect(() => {
  //   document.body.style.overflow = "hidden";
  //   return () => (document.body.style.overflow = "auto");
  // }, []);

  return (
    <div
      className="bg-[#00000069] w-[100%] h-[100%] z-40 fixed top-0 left-0 overflow-hidden flex justify-center items-center"
      onClick={() => {
        closeOnClickOutside && onCLose();
      }}
    >
      <div
        className="bg-white text-black p-4 rounded-[8px] z-50 relative"
        style={{ width: width + "%", height: height + "%" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {closeIconVisible && (
          <div className="absolute top-4 right-4">
            <X />
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default Modal;
