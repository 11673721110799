import React from "react";
import Select from "react-select";

const FormInput = ({
  type = "text",
  placeholder,
  options,
  value,
  name,
  onChange,
  error,
  defaultValue,
  checked,
  label,
  id,
  icon,
  wrapperClass = "",
  subWrapperClass = "",
  hideErrorMsg = false,
  customJSX,
  ...rest
}) => {
  const SelectStyles = {
    control: (base, state) => ({
      ...base,
      border: "0px solid var(--primary-border)",
      "&:hover": {
        border: "0px solid var(--primary-border)",
      },
      boxShadow: state.isFocused ? "none" : "none",
      color: "black",
      background: state.isDisabled ? "var(--primary-disabled)" : "#fff",
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
      ...provided,
      color: isSelected ? "white" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "black",
      background: state.isDisabled ? "var(--primary-disabled)" : "#fff",
    }),
  };

  const SelectTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "var(--primary-light-accent)",
      primary: "var(--primary-accent)",
      primary50: "var(--primary-light-accent)",
      neutral50: "#9CA3AF",
    },
  });

  const renderField = (type) => {
    switch (type) {
      case "select": {
        return (
          <Select
            options={options}
            components={{
              IndicatorSeparator: () => null,
            }}
            theme={SelectTheme}
            styles={SelectStyles}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            id={name}
            className="capitalize w-full h-[40px]"
            instanceId={name}
            isDisabled={rest?.disabled}
          />
        );
      }

      case "radio": {
        return (
          <input
            type={"radio"}
            name={name}
            className="bg-yellowColor"
            onChange={onChange}
            value={value}
            checked={checked}
            id={id}
          />
        );
      }

      case "switch": {
        return (
          <label htmlFor="toggleB" className="flex items-center cursor-pointer">
            {/* <!-- toggle --> */}
            <div className="relative">
              {/* <!-- input --> */}
              <input
                type="checkbox"
                id="toggleB"
                className="sr-only"
                checked={checked}
                onChange={onChange}
              />
              {/* <!-- line --> */}
              <div className="block bg-[#333333] dark:bg-white w-14 h-8 rounded-full"></div>
              {/* <!-- dot --> */}
              <div className="dot absolute left-1 top-1 bg-white dark:bg-[#333333] w-6 h-6 rounded-full transition"></div>
            </div>
          </label>
        );
      }

      case "textarea": {
        return (
          <textarea
            rows={6}
            className={`w-[100%] p-2 focus:outline-none bg-dark-black ${
              error && "border-[#FF0A00] border-[0.01em]"
            }`}
            placeholder={placeholder}
            style={{ resize: "none" }}
            onChange={onChange}
            value={value}
            name={name}
          ></textarea>
        );
      }

      case "number": {
        return (
          <input
            className={`p-2 focus:outline-none flex-1 rounded-[8px]`}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            type="number"
          />
        );
      }

      default:
        return (
          <input
            className={`p-2 focus:outline-none flex-1 rounded-[8px] ${
              rest?.disabled ? " bg-primary-disabled " : ""
            }`}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            type={type}
            {...rest}
          />
        );
    }
  };

  return (
    <div className={`w-full rounded-[8px] ${wrapperClass}`}>
      {label && (
        <div className="mb-1 ">
          <span>{label}</span>
        </div>
      )}
      <div
        className={`w-full border rounded-[8px] flex items-center p-1 gap-2 ${
          error ? " border-[#FF0A00] " : " border-primary-border "
        } ${icon ? " pl-2 " : "  "} ${
          rest?.disabled ? " bg-primary-disabled " : " "
        } ${subWrapperClass}`}
      >
        {icon && icon}
        {renderField(type)}
        {customJSX && customJSX}
      </div>
      {!hideErrorMsg && error && (
        <span className="text-[#FF0A00] text-[12px]">{error}</span>
      )}
    </div>
  );
};

export default FormInput;
