import { axiosGet } from "./axios.js";

export const getProductsByCategoryApi = (categoryId) => {
  return axiosGet(`/api/products/by-category/${categoryId}/`);
};

export const getBestSellersProductsApi = () => {
  return axiosGet("/api/products/best-sellers/");
};

export const getProductDetails = (productId) => {
  return axiosGet(`/api/products/${productId}`);
};
