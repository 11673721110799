import React, { Suspense } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { routes } from "./Routes";
import Layout from "../layout/Layout";
import { useSelector } from "react-redux";

const Router = () => {
  const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);

  const FinalRoute = ({
    routeProps: {
      component: Component,
      meta: { isPublicLayout, authRequired },
    },
  }) => {
    if (authRequired && !isUserLoggedIn) {
      return <Navigate to="/" />;
    }

    return (
      isPublicLayout && (
        <Layout>
          <Component />
        </Layout>
      )
    );
  };

  const router = createBrowserRouter(
    routes.map((routeItem) => {
      return {
        path: routeItem.path,
        element: (
          <Suspense fallback={<p> Loading...</p>}>
            <FinalRoute routeProps={routeItem} />
          </Suspense>
        ),
      };
    })
  );
  return <RouterProvider router={router} />;
};
export default Router;
