import { axiosGet, axiosPost, axiosPut, axiosDelete } from "./axios.js";

export const register = (payload) => {
  return axiosPost("/api/users/register/", payload);
};

export const login = (payload) => {
  return axiosPost("/api/users/login/", payload);
};

export const getUserDetails = () => {
  return axiosGet(`/api/users/user-profile/`);
};

export const updateUserDetails = (payload) => {
  return axiosPut("/api/users/user-profile/", payload);
};

export const addAddress = (payload) => {
  return axiosPost("/api/users/addresses/", payload);
};

export const editAddress = (payload) => {
  return axiosPut(`/api/users/addresses/${payload.id}/`, payload);
};

export const deleteAddress = (addressID) => {
  return axiosDelete(`/api/users/addresses/${addressID}/`);
};
