import React from "react";

const Dropdown = ({
  children,
  trigger,
  offsetX = 10,
  offsetY = 10,
  minWidth = 200,
}) => {
  return (
    <div className="group relative ">
      {trigger}
      <div
        style={{ top: offsetY, left: offsetX, minWidth: minWidth }}
        className="invisible absolute z-50 bg-white p-2 text-gray-800 shadow-[0_8px_30px_rgb(0,0,0,0.12)] group-hover:visible max-h-[400px] overflow-auto transition-all duration-200 ease-in-out  opacity-0 group-hover:opacity-100"
      >
        {children && children}
      </div>
    </div>
  );
};

export default Dropdown;
