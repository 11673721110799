import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userdetails: null,
  isUserLoggedIn: false,
};

export const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    handleUserDetails: (state, action) => {
      state.userdetails = action.payload;
    },
    handleIsUserLoggedIn: (state, action) => {
      state.isUserLoggedIn = action.payload;
    },
    addUserAddress: (state, action) => {
      state.userdetails.addresses?.push(action.payload);
    },
    updateUserAddress: (state, action) => {
      state.userdetails.addresses = state.userdetails.addresses.map((el) => {
        if (el.id === action.payload.id) {
          return action.payload;
        }
        return el;
      });
    },
    deleteUserAddress: (state, action) => {
      state.userdetails.addresses = state.userdetails.addresses.filter(
        (el) => el.id != action.payload
      );
    },
  },
});

export const {
  handleUserDetails,
  handleIsUserLoggedIn,
  addUserAddress,
  updateUserAddress,
  deleteUserAddress,
} = userSlice.actions;

export default userSlice.reducer;
