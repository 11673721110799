import React from "react";
import CustomLoader from "./CustomLoader";

const CustomButton = ({
  type,
  children,
  isSubmit = false,
  customClass = "",
  isLoading = false,
  disabled = false,
  ...rest
}) => {
  const generateCustomButton = (buttonType) => {
    switch (buttonType) {
      case "action":
        return (
          <button
            type={isSubmit ? "submit" : "button"}
            className={`relative group border border-primtext-primary-dark-accent  py-2 px-8 transition-all duration-200 ease-in-out overflow-hidden text-primary-dark-accent hover:text-white  ${customClass}`}
            {...rest}
          >
            <p className="z-20 ">{children}</p>
            <div className="absolute top-0 z-[-1]  left-0 translate-y-full group-hover:translate-y-0  w-full h-full transition duration-250 ease-in-out rounded-30 bg-primtext-primary-dark-accent "></div>
          </button>
        );

      case "text":
        return (
          <button type={isSubmit ? "submit" : "button"} {...rest}>
            {children}
          </button>
        );

      case "secondary":
        return (
          <button
            type={isSubmit ? "submit" : "button"}
            className={`rounded-[8px] border border-primary-dark-accent text-primary-dark-accent py-2 px-8 transition-all duration-200 ease-in-out ${customClass}`}
            {...rest}
            disabled={disabled || isLoading}
          >
            {children}
          </button>
        );

      default:
        return (
          <button
            type={isSubmit ? "submit" : "button"}
            className={`relative py-2 px-8 rounded-[8px]  flex justify-center items-center transition-all duration-200 ease-in-out ${
              disabled
                ? " bg-[#e2e2e2] text-black cursor-not-allowed"
                : " bg-primary-accent text-white"
            } ${customClass} `}
            disabled={disabled || isLoading}
            {...rest}
          >
            <div className={`${isLoading ? " opacity-0 " : " opacity-100 "}`}>
              {children}
            </div>
            {isLoading && <CustomLoader />}
          </button>
        );
    }
  };
  return generateCustomButton(type);
};

export default CustomButton;
