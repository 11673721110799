// import storage from "redux-persist/lib/storage";
// import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import user from "./user/user.slice";
import product from "./products/product.slice";

// const persistConfig = {
//   key: "test-app-key-root",
//   storage,
//   whitelist: [],
// };

const rootReducer = combineReducers({
  user: user,
  product: product,
});

export default rootReducer;

// export default persistReducer(persistConfig, rootReducer);
