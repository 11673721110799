import Router from "./router/Router";
import Toast from "./components/core/Toast";
import { useEffect, useState } from "react";
import { fetchUserDetailsAction } from "./redux/user/user.actions.js";
import {
  getBestSellersProductsAction,
  getCategoriesAction,
} from "./redux/products/product.action.js";

function App() {
  const [isAppLoaded, setIsAddLoaded] = useState(false);

  const initApp = async () => {
    try {
      fetchUserDetailsAction();
      await getCategoriesAction();
      getBestSellersProductsAction();
      setIsAddLoaded(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    initApp();
  }, []);
  return (
    <div className="App h-full relative">
      {isAppLoaded && <Router />}
      <Toast />
    </div>
  );
}

export default App;
