import { toastify } from "../../components/core/Toast";
import {
  addAddress,
  deleteAddress,
  editAddress,
  getUserDetails,
  login,
} from "../../services/auth.services";
import { getAccessToken } from "../../utils/helper";
import { store } from "../store";
import {
  handleUserDetails,
  handleIsUserLoggedIn,
  addUserAddress,
  updateUserAddress,
  deleteUserAddress,
} from "./user.slice";

export const loginUserAction = async (values) => {
  try {
    const isPhoneNumber = /^\d{10}$/.test(values.login);
    const isFormattedPhoneNumber = /^\+91\d{10}$/.test(values.login);

    const loginValue = isPhoneNumber
      ? "+91" + values.login
      : isFormattedPhoneNumber
      ? values.login
      : values.login;

    const loginUser = await login({
      ...values,
      login: loginValue,
    });

    if (loginUser && loginUser.status == 200 && loginUser.data) {
      localStorage.setItem("token", loginUser.data.access);
      localStorage.setItem("gujjuRefreshToken", loginUser.data.refresh);
      toastify("Logged in successfully");
      return true;
    }
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

export const logoutUserAction = async () => {
  localStorage.setItem("token", null);
  localStorage.setItem("gujjuRefreshToken", null);
  store.dispatch(handleUserDetails(null));
  store.dispatch(handleIsUserLoggedIn(false));
};

export const fetchUserDetailsAction = async () => {
  try {
    const accessToken = getAccessToken();
    if (accessToken) {
      const userDetails = await getUserDetails();
      if (userDetails && userDetails.status === 200 && userDetails.data) {
        updateUserDetailsAction({ data: userDetails.data, isLoggedIn: true });
      }
      return true;
    }
  } catch (error) {
    throw error;
  }
};

export const updateUserDetailsAction = ({ data, isLoggedIn }) => {
  store.dispatch(handleUserDetails(data));
  isLoggedIn !== undefined && store.dispatch(handleIsUserLoggedIn(isLoggedIn));
};

export const addUserAddressAction = async (payload) => {
  try {
    const addr = await addAddress(payload);
    if (addr?.status === 201 && addr?.data) {
      store.dispatch(addUserAddress(addr?.data));
      return true;
    }
  } catch (error) {
    throw error;
  }
};

export const editUserAddressAction = async (payload) => {
  try {
    const addr = await editAddress(payload);
    if (addr?.status === 200 && addr?.data) {
      store.dispatch(updateUserAddress(addr.data));
      return true;
    }
  } catch (error) {
    throw error;
  }
};

export const deleteUserAddressAction = async (addressID) => {
  try {
    const addr = await deleteAddress(addressID);
    if (addr?.status === 204) {
      store.dispatch(deleteUserAddress(addressID));
      return true;
    }
  } catch (error) {
    throw error;
  }
};
